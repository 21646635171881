const PDF_BASE_URL = 'https://cdn2.yieldstreet.com/pdfs';

export enum PdfLinkKeys {
  FORM_ADV = 'formAdv',
  FORM_ADV_2B = 'formAdv2B',
  FORM_CRS = 'formCrs',
  UNSGND_CLIENT_AGREEMENT = 'unsignedClientAgreement',
  PCG_AGREEMENT = 'pcgAgreement',
  WILSHIRE = 'wilshire',
  YS_CLIENT_ADVISORY_AGREEMENT = 'YSClientAdvisoryAgreement',
  ATOMIC_BROKERAGE_AGREEMENT = 'atomicBrokerageAgreement',
}

export const pdfLinks: { [key in PdfLinkKeys]: string } = {
  [PdfLinkKeys.FORM_ADV]: `${PDF_BASE_URL}/YSM-Form-ADV-March-2024-Annual-Amendment-Submitted.pdf`,
  [PdfLinkKeys.FORM_ADV_2B]: `${PDF_BASE_URL}/YSM-Form-ADV-Part-2B-January-2024.pdf`,
  [PdfLinkKeys.FORM_CRS]: `${PDF_BASE_URL}/YSM-Form-CRS-March-2024.pdf`,
  [PdfLinkKeys.UNSGND_CLIENT_AGREEMENT]: `${PDF_BASE_URL}/IAA-Yieldstreet-Recommendations-Digital-Non-Discretionary-v-123.pdf`,
  [PdfLinkKeys.PCG_AGREEMENT]: `${PDF_BASE_URL}/IAA-Yieldstreet-Advice-PCG-Non-Discretionary-v-123.pdf`,
  [PdfLinkKeys.WILSHIRE]:
    'https://info.wilshire.com/wilshire-2025-asset-allocation-return-and-risk-assumptions',
  [PdfLinkKeys.YS_CLIENT_ADVISORY_AGREEMENT]:
    'https://cdn2.yieldstreet.com/wp-content/uploads/2025/04/01105341/IAA-YS-Managed-Portfolios-Final-March-2025.pdf',
  [PdfLinkKeys.ATOMIC_BROKERAGE_AGREEMENT]:
    'https://legal.atomicvest.com/usa.broker.agreement.9fd3cb75-d1c5-4b75-8bb8-af05138de439.pdf',
};
